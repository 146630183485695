/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Button from '@common_button';
import Show from '@common_show';
import cx from 'classnames';
// import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import useMediaQuery from '@hook/useMediaQuery';
import UrbanIcon from '@core_modules/theme/plugins/urbanicon';

const VISIBLE_SLIDE_PER_SCREENSIZE = {
    desktop: 4,
    tablet: 3,
    mobile: 2,
};

const ContainerScroll = ({
    variant = 'horizontal',
    className,
    classNameContainer,
    children,
    maxHeight = '100%',
    maxWidth = '100%',
    showArrow,
    arrowSize = 10,
    slidesToScroll = 1,
    style,
    arrowProps = {},
    showDots = false,

    gapDesktop = 16,
    gapTablet = 12,
    gapMobile = 8,
}) => {
    const containerRef = React.useRef(null);
    const isHorizontal = variant === 'horizontal';
    const isVertical = variant === 'vertical';
    // calculates the width of the first child from the list rendered
    const [containerChildrenWidth, setContainerChildrenWidth] = React.useState(0);

    const { leftNavClassName = '', rightNavClassName = '' } = arrowProps;

    const { isDesktop, isTablet } = useMediaQuery();
    let gap;
    if (isDesktop) gap = gapDesktop;
    else if (isTablet) gap = gapTablet;
    else gap = gapMobile;

    // Handle dots
    const calculateNumberOfDots = (totalSlideItems, visibleSlideItems, slideItemsToScroll) => (
        (Math.ceil((totalSlideItems - visibleSlideItems) / slideItemsToScroll)) + 1
    );

    const [dots, setDots] = React.useState(0);
    const [activeDots, setActiveDots] = React.useState(0);
    React.useEffect(() => {
        if (containerRef?.current?.children?.length > 0) {
            const childrenLength = containerRef?.current?.children?.length;
            let numberOfDots;
            if (isDesktop) {
                numberOfDots = calculateNumberOfDots(childrenLength, VISIBLE_SLIDE_PER_SCREENSIZE.desktop, slidesToScroll);
            } else if (isTablet) {
                numberOfDots = calculateNumberOfDots(childrenLength, VISIBLE_SLIDE_PER_SCREENSIZE.tablet, slidesToScroll);
            } else {
                numberOfDots = calculateNumberOfDots(childrenLength, VISIBLE_SLIDE_PER_SCREENSIZE.mobile, slidesToScroll);
            }
            setDots(numberOfDots);
        }
    }, [containerRef, children, isDesktop, isTablet]);

    const onClickArrowLeft = () => {
        containerRef.current.scrollLeft -= containerChildrenWidth;
        if (activeDots > 0) {
            setActiveDots(activeDots - 1);
        }
    };

    const onClickArrowRight = () => {
        containerRef.current.scrollLeft += containerChildrenWidth;
        if (activeDots < (dots - 1)) {
            setActiveDots(activeDots + 1);
        }
    };

    const onClickDot = (idx) => {
        const slideRange = activeDots - idx;
        containerRef.current.scrollLeft -= containerChildrenWidth * slideRange;
        setActiveDots(idx);
    };

    const calculateContainerChildrenWidth = (childrenWidth, slideItemGap, slideItemsToScroll) => (
        (childrenWidth + slideItemGap) * slideItemsToScroll
    );
    React.useEffect(() => {
        if (containerRef?.current?.children?.length > 0) {
            const childrenWidth = calculateContainerChildrenWidth(containerRef.current.children[0].clientWidth, gap, slidesToScroll);
            setContainerChildrenWidth(childrenWidth);
        }
    }, [containerRef, children, isDesktop, isTablet]);

    return (
        <div
            className={cx('container-scroll flex flex-col relative group/container-scroll h-auto', classNameContainer)}
            style={{
                ...(maxHeight ? { maxHeight } : null),
                ...(maxWidth ? { maxWidth } : null),
                ...style,
            }}
        >
            <Show when={showArrow}>
                <div
                    className={cx(
                        'container-scroll-arrow flex justify-between w-[100%]',
                        'px-[5px]',
                        'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
                        'opacity-0',
                        'group-hover/container-scroll:opacity-100',
                        'z-[3]',
                        'pointer-events-none',
                        'order-0',
                        arrowProps.className || '',
                    )}
                >
                    <Button
                        variant="plain"
                        className={cx(
                            'container-scroll-arrow-left',
                            'bg-neutral-white',
                            '!px-[10px]',
                            'pointer-events-auto',
                            'shadow-md',
                            'group/left-nav',
                            '!rounded-[5px]',
                            '!opacity-100',
                            'shadow-[0 2px 10px rgba(0,0,0,.1)]',
                            '!bg-[unset]',
                            leftNavClassName,
                        )}
                        classNameText="!text-neutral group-hover/left-nav:!text-yellow-300"
                        onClick={onClickArrowLeft}
                    >
                        <UrbanIcon
                            className="w-6 h-6 !text-neutral-black group-hover/left-nav:!text-yellow-300"
                            iconContent='"\e93d"'
                            width={arrowSize}
                            height={arrowSize}
                        />
                        {/* <ChevronLeftIcon className="w-6 h-6" style={{ width: arrowSize, height: arrowSize }} /> */}
                    </Button>
                    <Button
                        variant="plain"
                        className={cx(
                            'container-scroll-arrow-right',
                            'bg-neutral-white',
                            '!px-[10px]',
                            'pointer-events-auto',
                            'shadow-md',
                            'group/right-nav',
                            '!rounded-[5px]',
                            '!opacity-100',
                            'shadow-[0 2px 10px rgba(0,0,0,.1)]',
                            '!bg-[unset]',
                            rightNavClassName,
                        )}
                        classNameText="!text-neutral-black group-hover/right-nav:!text-yellow-300"
                        onClick={onClickArrowRight}
                    >
                        <UrbanIcon
                            className="w-6 h-6 rotate-180 !text-neutral-black group-hover/right-nav:!text-yellow-300"
                            iconContent='"\e93d"'
                            width={arrowSize}
                            height={arrowSize}
                        />
                        {/* <ChevronRightIcon className="w-6 h-6" style={{ width: arrowSize, height: arrowSize }} /> */}
                    </Button>
                </div>
            </Show>
            <div
                ref={containerRef}
                style={{
                    ...(maxHeight ? { maxHeight } : null),
                    ...(maxWidth ? { maxWidth } : null),
                    gap,
                }}
                className={cx(
                    'w-full',
                    'swift-container-scroll-data',
                    'relative',
                    'scrollbar-none',
                    'pb-4',
                    isHorizontal && 'overflow-x-auto flex scroll-smooth',
                    isVertical && 'overflow-y-auto',
                    className,
                )}
            >
                {children}
            </div>
            {(showDots && (dots > 0)) && (
                <div className="flex flex-row gap-x-5 justify-center mt-4">
                    {[...Array(dots)].map((data, idx) => (
                        <>
                            <div
                                key={idx}
                                className={cx(
                                    ' h-2.5 rounded-full cursor-pointer',
                                    activeDots === idx ? 'w-[25px] bg-yellow-300' : 'w-2.5 bg-neutral-300',
                                )}
                                onClick={() => onClickDot(idx)}
                            />
                        </>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ContainerScroll;
